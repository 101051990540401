import { Button } from '@material-ui/core'
import { yellow } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/core/styles'
import { Link as RouterLink  } from '@reach/router'
import React from 'react'

export default function LinkButton({text, variant, bgColor, fontColor, route, className, isExternal = false }) {
  const useStyles = makeStyles((theme) => ({
    button: {
      color: fontColor,
      backgroundColor: bgColor,
      textTransform: "none",
      "&:hover": {
        color: fontColor,
        backgroundColor: bgColor,
      },
      filter: "drop-shadow(0px 4px 4px rgba(0,0,0,0.25));",
      "& a": {
        flexGrow: 0,
      },
      "& *": {
        flexGrow: 0
      }
    },
    large: {
      borderRadius: "100px",
      padding: "20px 40px",
      fontWeight: "bold",
      fontSize: "20px",
      textAlign: "center",
      "&:hover": {
        color: "#fcfcfc",
        backgroundColor: "#f7b663",
      },
      filter: "drop-shadow(0px 4px 4px rgba(0,0,0,0.25));",
      "& a": {
        flexGrow: 0,
      },
      "& *": {
        flexGrow: 0
      }
    },
    medium: {
      borderRadius: "100px",
      padding: "12px 32px",
      fontWeight: "bold",
      fontSize: "18px",
      textAlign: "center",
      "&:hover": {
        color: "#fcfcfc",
        backgroundColor: "#f7b663",
      },
      filter: "drop-shadow(0px 4px 4px rgba(0,0,0,0.25));",
      "& a": {
        flexGrow: 0,
      },
      "& *": {
        flexGrow: 0
      }
    },
    small: {
      borderRadius: "100px",
      padding: "12px 32px",
      fontWeight: "bold",
      fontSize: "15px"
    },
    smaller: {
      borderRadius: "100px",
      padding: "10px 26px",
      fontWeight: "bold",
      fontSize: "14px",
      textAlign: "center",
      "&:hover": {
        color: "#fcfcfc",
        backgroundColor: "#f7b663",
      },
      filter: "drop-shadow(0px 4px 4px rgba(0,0,0,0.25));",
      "& a": {
        flexGrow: 0,
      },
      "& *": {
        flexGrow: 0
      }
    }
  }))
  const classes = useStyles();
 
  // If it's an external link, use an anchor tag
  if (isExternal) {
    return (
      <Button 
        className={`${className} ${classes.button} ${classes[variant]}`} 
        component="a" 
        href={route} 
        target="_blank" 
        rel="noopener noreferrer"
      >
        {text}
      </Button>
    )
  }

  // For internal routes, use Reach Router Link
  return (
    <Button className={`${className} ${classes.button} ${classes[variant]}`} component={RouterLink} to={route}>
      {text}
    </Button>
  )
}
