import React, { useState } from 'react'
import { Link as RouterLink, navigate, useLocation } from '@reach/router'
// Material UI
import { makeStyles } from '@material-ui/core/styles'
import {
  AppBar,
  Toolbar,
  Button,
  Link,
  Typography,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  ListItemIcon,
  Drawer,
  List,
  ListItem,
  Divider,
} from '@material-ui/core'
import {
  ExitToApp as LogoutIcon,
  Person as AccountIcon,
  AccountCircle as AccountCircleIcon,
  Face as ProfileIcon,
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  Dashboard as DashboardIcon,
} from '@material-ui/icons'
import clsx from 'clsx'
import { useSnackbar } from 'notistack'
import { mainRoutes, mobileRoutes } from '../routes'
// import Logo from '../assets/img/logo.png'
import Logo from '../assets/img/lit-logo.png'
// import LogoSVG from '../assets/img/logo.svg'
import LogoSVG from '../assets/img/lit-logo.png'
import { useAuth } from '../context/auth-context'
import { useUser } from '../context/user-context'
import ApplyNowButton from './ApplyNowButton'
import LinkButton from '../components/LinkButton'

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  navDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    height: '85px',
  },
  navMobile: {
    display: 'flex',
    height: '64px',
    justifyContent: 'space-between',
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  logoLink: {
    padding: 0,
    margin: 0,
  },
  menuIcon: {
    color: '#1B3666',
    fontSize: '48px',
  },
  logo: {
    alignItems: 'center',
    display: 'flex',
    marginRight: '40px',
    marginLeft: '8px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'unset',
      padding: '0px',
    },
  },
  logoImage: {
    height: '62.97px',
    [theme.breakpoints.up('sm')]: {
      height: 64,
    },
  },
  logoTitle: {
    color: theme.palette.common.white,
    fontWeight: 700,
  },
  menuButton: {
    // marginRight: theme.spacing(2),
  },
  links: {
    display: 'flex',
    alignItems: 'center',
    gap: '40px',
  },
  link: {
    color: theme.palette.common.black,
    textDecoration: 'none',
    textTransform: 'uppercase',
  },
  login: {
    marginLeft: theme.spacing(1),
  },
  active: {
    // textDecoration: 'underline',
    // color: theme.palette.secondary.main,
    color: '#D49F02',
  },
  actionItem: {
    color: theme.palette.common.black,
    textDecoration: 'none',
    margin: theme.spacing(1),
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: 280,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  activeItem: {
    backgroundColor: theme.palette.secondary.light,
  },
  donateButton: {
    background: '#1B3666',
    boxShadow: 'none',
    padding: '12px 24px',
    borderRadius: '100px',
    height: '45px',
    width: '100px',

    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '21px',
    letterSpacing: '0.025em',
    textAlign: 'center',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#132648',
    },
  },
  divider: {
    height: '36px',
    background: '#C6C9CE',
    border: '1px solid #C6C9CE',
    margin: theme.spacing(1),
  },
  text: {
    lineHeight: '24px',
    fontSize: ',16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    fontFamily: 'Inter',
  },
}))

export default function TopNavBar() {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const { pathname } = useLocation()
  const { user, reloadUser } = useUser()
  const { logout } = useAuth()
  const [anchorEl, setAnchorEl] = useState(null)
  const [openDrawer, setOpenDrawer] = useState(false)
  const open = Boolean(anchorEl)

  const menuItems =
    user?.Person?.role === 'therapist'
      ? [
          {
            path: `/view/therapist/${user.id}`,
            pathName: 'My Profile',
            icon: <ProfileIcon fontSize="small" />,
          },
          {
            path: '/me',
            pathName: 'My Details',
            icon: <AccountIcon fontSize="small" />,
          },
          {
            path: '/',
            pathName: 'Logout',
            icon: <LogoutIcon fontSize="small" />,
          },
        ]
      : user?.Person?.role === 'admin' || user?.role === 'admin'
      ? [
          {
            path: '/admin',
            pathName: 'Dashboard',
            icon: <DashboardIcon fontSize="small" />,
          },
          {
            path: '/',
            pathName: 'Logout',
            icon: <LogoutIcon fontSize="small" />,
          },
        ]
      : [
          {
            path: '/',
            pathName: 'Logout',
            icon: <LogoutIcon fontSize="small" />,
          },
        ]

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    handleMenuClose()
    logout()
      .then(async () => {
        enqueueSnackbar('Logout success', { variant: 'success' })
        reloadUser()
        await navigate('/')
      })
      .catch(() => {
        enqueueSnackbar('Logout failed', { variant: 'error' })
      })
  }

  const toggleDrawer = (openStatus) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    setOpenDrawer(openStatus)
  }

  return (
    <>
      <AppBar position="relative">
        <Toolbar style={{ backgroundColor: 'white' }}>
          <div className={clsx(classes.navDesktop, classes.grow)}>
            <Link className={classes.logo} component={RouterLink} to="/">
              <img src={Logo} alt="logo" className={classes.logoImage} />
            </Link>
            <div variant="h6" className={classes.links}>
              {mainRoutes.map((r) => {
                if (!r.name) {
                  return
                }
                return (
                  <Link
                    key={r.path}
                    component={RouterLink}
                    to={r.path}
                    className={pathname === r.path ? clsx(classes.link, classes.active) : classes.link}
                  >
                    <Typography variant="h6" component="span" className={classes.text}>
                      {r.name}
                    </Typography>
                  </Link>
                )
              })}
              {/*<Link style={{color: '#D49F02'}} to="/help" component={RouterLink}>*/}
              {/*  <Button variant="outlined" style={{color: '#D49F02'}} size="large">*/}
              {/*    Get Support Now*/}
              {/*  </Button>*/}
              {/*</Link>*/}
            </div>
            <div className={classes.grow} style={{ marginLeft: 'auto', justifyContent: 'right' }}>
            <LinkButton
              fontColor="black"
              bgColor="#FFDB15"
              route="https://csbdhopeforhealing.org/"
              isExternal={true} 
              text="CSBD Hope for Healing Conference"
              variant={ 'smaller'}
            />
            </div>
            <div className={classes.grow} style={{ marginLeft: 'auto', justifyContent: 'right' }}>
              <ApplyNowButton />
              <Link
                key="/donate"
                component={RouterLink}
                to="/donate"
                className={pathname === '/donate' ? clsx(classes.actionItem, classes.active) : classes.actionItem}
                // className={pathname === "/donate" && clsx(classes.link, classes.active)}
              >
                <Button
                  variant="contained"
                  size="medium"
                  className={clsx(classes.text, classes.donateButton)}
                  style={{ fontWeight: '700' }}
                >
                  Donate
                </Button>
              </Link>
              <Divider className={classes.divider} orientation="vertical" />
              {!user && (
                <Link
                  className={
                    pathname === '/login/:display' ? clsx(classes.actionItem, classes.active) : classes.actionItem
                  }
                  component={RouterLink}
                  to="/login/:display"
                  style={
                    pathname === '/login/:display'
                      ? { color: '#D49F02', marginRight: '32px' }
                      : { color: 'black', marginRight: '32px' }
                  }
                >
                  <Typography variant="h6" component="span" className={classes.text}>
                    Log In
                  </Typography>
                </Link>
              )}
            </div>
          </div>

          <div className={classes.navMobile}>
            <Link className={classes.logo} component={RouterLink} to="/">
              <img src={LogoSVG} alt="logo" className={classes.logoImage} />
            </Link>
            <div className={classes.grow} style={{ justifyContent: 'right', paddingRight: '20px' }}>
              <Link
                key="/donate"
                component={RouterLink}
                to="/donate"
                className={pathname === '/donate' ? clsx(classes.link, classes.active) : classes.link}
                // className={pathname === "/donate" && clsx(classes.link, classes.active)}
              >
                <Button variant="contained" size="medium" className={classes.donateButton}>
                  Donate
                </Button>
              </Link>
            </div>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon className={classes.menuIcon} />
            </IconButton>
          </div>
          {user ? (
            <Tooltip title="Account Menu">
              <IconButton aria-label="show more" aria-haspopup="true" onClick={handleMenuOpen} color="secondary">
                <AccountCircleIcon color="primary" fontSize="large" />
              </IconButton>
            </Tooltip>
          ) : (
            <div />
          )}
        </Toolbar>
      </AppBar>
      {/* Extra toolbar to make sure content not below appbar - https://material-ui.com/components/app-bar/#fixed-placement */}
      <Drawer
        open={openDrawer}
        onClose={toggleDrawer(false)}
        classes={{
          paper: clsx(classes.drawerPaper, !openDrawer && classes.drawerPaperClose),
        }}
        // PaperProps={{ classes: { root: classes.drawerPaper } }}
      >
        <div className={classes.toolbarIcon}>
          <MenuItem
            key="/donate"
            component={RouterLink}
            to="/donate"
            className={pathname === '/donate' ? clsx(classes.link, classes.active) : classes.link}
            // className={pathname === "/donate" && clsx(classes.link, classes.active)}
          >
            <Button variant="contained" size="medium" className={classes.donateButton} style={{ marginRight: '14px' }}>
              Donate
            </Button>
          </MenuItem>
          <IconButton onClick={toggleDrawer(false)}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />

        <div className={classes.list} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
          <List component="nav">
            {mobileRoutes.map((r) => (
              <ListItem
                button
                key={r.path}
                component={RouterLink}
                to={r.path}
                className={pathname === r.path ? classes.activeItem : ''}
              >
                <Typography variant="h6" component="span">
                  {r.name}
                </Typography>
              </ListItem>
            ))}
          </List>
        </div>
      </Drawer>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleMenuClose}
      >
        {menuItems.map((item) => (
          <MenuItem
            key={item.path}
            component={RouterLink}
            to={item.path}
            onClick={item.pathName === 'Logout' ? handleLogout : handleMenuClose}
          >
            {item.icon && <ListItemIcon className={classes.menuIcon}>{item.icon}</ListItemIcon>}
            <Typography variant="inherit">{item.pathName}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
